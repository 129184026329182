.mainPage {
    display: flex;
    /* background-color: #b4a095; */
    background-color: white;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    max-width: 820px;
    margin: 0 auto;
}
