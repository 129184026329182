.mainPage__info-subtitle {
    font-family: 'Montserrat-Light', sans-serif;
    font-size: 24px;
    text-align: center;
    margin: 0;
    padding: 150px 0;
    color: black;
}

@media (max-width: 920px) {
    .mainPage__info-subtitle {
        font-family: 'Montserrat-Light', sans-serif;
        font-size: 24px;
        text-align: center;
        padding: 0;
        margin: 30px 0;
    }
}