.mainPage__info {
    text-align: center !important;
    font-size: 22px;
    margin: 110px 0 10px;
    padding: 0;
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 300;
    text-align: center;
    color: #000;
    text-align: left;
    opacity: 0.70;
}