.mainPage__footer-button {
    font-family: 'Inter', Arial, Helvetica;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    margin: 30px auto;
    width: 248px;
    height: 50px;
    color: white;
    background-color: black;
    transition: opacity 0.7s;
}

.mainPage__footer-button:hover{
    cursor: pointer;
    opacity: 0.7;
}