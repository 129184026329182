.mainPage__menu {
    position: fixed;
    width: 30px;
    height: 30px;
    top: 30px;
    right: 30px;
    transition: opacity 0.7s;
    background-color: rgba(0, 0, 0, 0.199);
}

.mainPage__menu:hover {
    cursor: pointer;
    opacity: 0.7;
}

@media (max-width: 900px) {
    .mainPage__menu {
        border-radius: 17%;
        background-color: transparent;
    }
}