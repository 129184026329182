.mainPage__container {
    display: flex;
    flex-direction: column;
    margin:0;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-height: 900px;
    position: relative;
    background-color: white;
    width: 100%;
}